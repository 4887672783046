import React, { useEffect, useState } from "react";
import getGenres from "../utilities/genres.js";
import Popup from "reactjs-popup";
import Switch from "./switch";

function GenresSwitch(props) {
  const [isOpen, setOpen] = useState(false);
  const [genreList, setGenreList] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState(new Set());

  useEffect(() => {
    getGenres().then((genreList) => {
      setGenreList(genreList);
    });
  }, []);

  useEffect(() => {
    let genreSet;

    if (props.genres) {
      genreSet = new Set(props.genres.split(","));
    } else {
      genreSet = new Set(Object.keys(genreList));
    }

    setSelectedGenres(genreSet);
  }, [props.genres, genreList]);

  if (!genreList) {
    return null;
  }

  const genreKeys = Object.keys(genreList);

  const setSelected = (checked, key) => {
    if (checked) {
      selectedGenres.add(key);
    } else {
      selectedGenres.delete(key);
    }
    setSelectedGenres(selectedGenres);
  };

  const onFilterAply = () => {
    setOpen(false);
    props.onFiltered(Array.from(selectedGenres).join(","));
  };

  const onDeselectAll = () => {
    setSelectedGenres(new Set());
  };

  const onSelectAll = () => {
    const genreSet = new Set(Object.keys(genreList));
    setSelectedGenres(genreSet);
  };

  const filteredText =
    Object.keys(genreList).length === selectedGenres.size
      ? "Vše"
      : `${selectedGenres.size}`;

  return (
    <Popup
      trigger={
        <button className="sort-label">
          <img
            src={process.env.PUBLIC_URL + "/img/genre.svg"}
            title="menu"
            alt="menu"
          />
          <span className="sort-button-text">{filteredText}</span>
        </button>
      }
      position="right top"
      lockScroll
      nested
      closeOnDocumentClick
      closeOnEscape
      arrow={false}
      onOpen={() => setOpen(true)}
      open={isOpen}
      offsetX={-120}
    >
      {genreKeys.map((genre) => (
        <Switch
          key={genre}
          switchKey={genre}
          text={genreList[genre]}
          checked={selectedGenres.has(genre)}
          onClick={(checked, key) => setSelected(checked, key)}
        />
      ))}
      <div className="buttons-container">
        <button className="button-label" onClick={onDeselectAll}>
          Odebrat všechny
        </button>
        <button className="button-label" onClick={onSelectAll}>
          Vybrat všechny
        </button>
        <button className="button-label" onClick={onFilterAply}>
          Aplikovat
        </button>{" "}
      </div>
    </Popup>
  );
}

export default GenresSwitch;
