import React from "react";
import RatingBigList from "./rating-big-list.js";
import Flags from "./flags.js";
import { prominent } from "color.js";
import Credits from "./credits.js";
import ShowMoreText from "react-show-more-text";
import Performances from "./performance-list.js";
import CloseButton from "./close-button.js";
import PremiereDetail from "./premiere-detail.js";
import Overlay from "./overlay";
import Genres from "./genres";
import { neutralizeBack, revivalBack } from "../utilities/modal";
import { cookieGetFilterRange } from "../utilities/cookie-store";
import RangeInfo from "./movie-detail-range-info";
import YouTubeTrailers from "./trailers.js";
class MovieDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      movieName: null,
      originalName: null,
      posterUrl: null,
      plot: null,
      rating: null,
      released: null,
      averageRating: null,
      isDetailOpened: false,
      countries: [],
      backdropUrl: null,
      performances: [],
      trailers: [],
    };
  }

  render() {
    const prominentColor = this.state.prominentColor
      ? this.state.prominentColor.join(", ")
      : "0, 0, 0";

    const expandedTextProps = {
      lines: 8,
      more: "⊕",
      less: "⊖",
      truncatedEndingComponent: "... ",
    };

    const opacity = this.state.prominentColor ? "0.75" : "0.9";

    const range = this.props.applyPerformanceFilter
      ? this.state.filterRange
      : { from: 0, to: 24 };

    return (
      <div className="modal" onClick={(event) => this._modalClicked(event)}>
        <Overlay isDisplayed={this.state.isLoading} />
        {!this.state.isLoading && (
          <section
            className="movie-detail"
            style={{
              backgroundImage: `linear-gradient(rgba(${prominentColor}, ${opacity}), rgba(${prominentColor}, ${opacity})), url(${this.state.backdropUrl})`,
              color: this.state.isBackdropLight ? "black" : "white",
            }}
          >
            <CloseButton onClose={() => this._hideModal()} />
            <div className="movie-content">
              <div>{this._getMovieTitle()}</div>
              <div className="movie-flags">
                {this.state.originalName !== this.state.movieName && (
                  <h2>{this.state.originalName}</h2>
                )}
                <Flags countries={this.state.countries} />

                {this.state.runtime && (
                  <span className="movie-detail-runtime">
                    {"\u00A0"}
                    {this.state.runtime}
                    {"\u00A0minut"}
                  </span>
                )}
              </div>
              <Genres genres={this.state.genres} />
              <RatingBigList rating={this.state.rating} />
              <Credits credits={this.state.credits} />
              <div className="movie-detail-plot">
                {this.state.plot && (
                  <>
                    <h2>Obsah</h2>
                    <ShowMoreText {...expandedTextProps}>
                      {this.state.plot}
                    </ShowMoreText>
                  </>
                )}
              </div>
              {this.state.performances.length < 1 && (
                <PremiereDetail date={this.state.latestCzechPremiere} />
              )}
              {this.state.visible &&
                this.state.trailers &&
                this.state.trailers.length > 0 && (
                  <YouTubeTrailers trailers={this.state.trailers} />
                )}
              {this.state.performances && this.state.performances.length > 0 && (
                <>
                  <RangeInfo range={range} />
                  <Performances
                    performances={this.state.performances}
                    uniqueFlags={this.state.uniqueFlags}
                    highlightedDate={this.props.contextDate}
                    range={range}
                  />
                </>
              )}
            </div>
          </section>
        )}
      </div>
    );
  }

  _getMovieTitle() {
    let movieElement;

    if (this.state.homepage) {
      movieElement = (
        <a href={this.state.homepage} target="_blank" rel="noopener noreferrer">
          {this._getPlainTitle()}
        </a>
      );
    } else {
      movieElement = this._getPlainTitle();
    }

    return movieElement;
  }

  _getPlainTitle() {
    return (
      <>
        <h1 className="movie-detail-title">{this.state.movieName}</h1>
        {this.state.released && (
          <span className="movie-detail-released">
            {"\u00A0"}({this.state.released})
          </span>
        )}
      </>
    );
  }

  showMovie(id) {
    this.setState({ isLoading: true, visible: true });
    this._showModal();
    this._fetchMovieDetail(id);
  }

  _setMovieDetail(json) {
    if (json.backdropUrl) {
      prominent(json.backdropUrl, { amount: 1 })
        .then((prominentColor) => {
          this._setFromJson(json, prominentColor);
        })
        .catch(() => {
          this._setFromJson(json);
        });
    } else {
      this._setFromJson(json);
    }
  }

  _showModal() {
    neutralizeBack(this._handleBrowserBack);
    this.modalElement.style.display = "block";
    this._scrollTopBody = document.documentElement.scrollTop;
    document.documentElement.style.overflow = "hidden";
  }

  _handleBrowserBack = () => this._hideModal();

  _hideModal() {
    this.setState({ visible: false });
    revivalBack();
    document.documentElement.scrollTop = this._scrollTopBody;
    this.modalElement.style.display = "none";
    document.documentElement.style.overflow = "";
  }

  modalElement = null;
  visible = false;

  componentDidMount() {
    this.modalElement = document.querySelector(".modal");
  }

  _modalClicked(event) {
    if (
      event.target &&
      (event.target.classList.contains("modal") ||
        event.target.classList.contains("movie-detail"))
    ) {
      this._hideModal();
    }
  }

  _fetchMovieDetail(id) {
    fetch(`/s-movie-detail/${id}`)
      .then((result) => result.json())
      .then((json) => {
        if (json.backdropUrl) {
          fetch(json.backdropUrl)
            .catch(() => {})
            .finally(() => {
              this._setMovieDetail(json);
            });
        } else {
          this._setMovieDetail(json);
        }
      });
  }

  _setFromJson(json, prominentColor) {
    let isLight = false;

    if (prominentColor) {
      const r = prominentColor[0];
      const g = prominentColor[1];
      const b = prominentColor[2];

      isLight =
        Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)) > 128.0;
    }

    const filterRange = cookieGetFilterRange() ?? "7-24";

    this.setState({
      movieName: json.czechName,
      originalName: json.originalName,
      posterUrl: json.posterUrl,
      backdropUrl: json.backdropUrl,
      plot: json.plot,
      rating: json.rating.ratings,
      homepage: json.homepage,
      countries: json.countries,
      runtime: json.runtime,
      released: json.year,
      prominentColor: prominentColor,
      isBackdropLight: isLight,
      credits: json.credits,
      performances: json.performances || [],
      trailers: json.youTubeTrailers || [],
      latestCzechPremiere: json.latestCzechPremiere,
      isLoading: false,
      uniqueFlags: json.uniqueFlags,
      genres: json.genres,
      filterRange: this._getRange(filterRange),
    });
  }

  _getRange = (range) => {
    const times = range.split("-");

    return { from: parseInt(times[0]), to: parseInt(times[1]) };
  };
}

export default MovieDetail;
