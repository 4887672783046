import React from "react";
import moment from "moment";

function PremiereDetail(props) {
  const premiere = _getPremiere(props.date);

  if (!premiere) {
    return null;
  }

  let prepostion = "Předpokládaná premiéra v ČR";

  if (premiere.inDays < 0) {
    prepostion = "Premiéra v ČR proběhla";
  }

  let daysText;

  switch (premiere.inDays) {
    case 0:
      daysText = "dnes";
      break;
    case 1:
      daysText = "zítra";
      break;
    case 2:
    case 3:
    case 4:
      daysText = `za ${premiere.inDays} dny`;
      break;
    default:
      daysText = `za ${premiere.inDays} dní`;
      break;
  }

  return (
    <div className="movie-detail-premiere" title="Premiéra v ČR">
      <img src={process.env.PUBLIC_URL + "/img/curtains.svg"} alt="" />
      {"\u00A0"}
      <span>{prepostion}</span>
      {"\u00A0"}
      <span>{premiere.date}</span>
      {"\u00A0"}
      {premiere.inDays > 0 && <span>{daysText}</span>}
    </div>
  );
}

function _getPremiere(date) {
  if (!date) {
    return null;
  }

  const mDate = moment(date, "YYYY-MM-DD");
  const now = moment();
  const inDays = parseInt(moment.duration(mDate.diff(now)).asDays());

  return { date: mDate.format("DD.MM.YYYY"), inDays: inDays };
}

export default PremiereDetail;
