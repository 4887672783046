import React from "react";
import { withRouter } from "react-router-dom";
import MovieList from "./movie-list";
import Sort from "./sort";
import Headroom from "react-headroom";
import Overlay from "./overlay";
import MovieDetail from "./movie-detail";
import Cookies from "universal-cookie";
import PopupMenu from "./popup-menu";

class MoviesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      movieIds: [],
      isLoading: false,
      movieDetail: null,
      total: 0,
    };

    this.movieDetail = React.createRef();
  }

  render() {
    return (
      <div>
        <div className="page">
          <Headroom>
            <Sort
              showRange={this.props.showRange}
              premiereVisible={this.props.premiereVisible}
              filtered={this.state.movieIds.length}
              total={this.state.total}
            />
          </Headroom>
          <div className="page-header">
            <img
              className="page-logo"
              src={process.env.PUBLIC_URL + "/img/vkine-header.svg"}
              alt="v kine.art logo"
            />
            <h1>{this.props.title}</h1> <PopupMenu />
          </div>
          <MovieList
            className="padding-on-top"
            movieIds={this.state.movieIds}
            onMovieDetailClick={(id) => this._openMovieDetail(id)}
          />
          <Overlay isDisplayed={this.state.isLoading} />
          <MovieDetail
            ref={this.movieDetail}
            contextDate={this.props.contextDate}
            applyPerformanceFilter={this.props.showRange}
          />
        </div>
      </div>
    );
  }

  _fetchMovies(searchParameters) {
    const endpoint = this.props.endpoint;

    this.setState({ isLoading: true });

    fetch(
      endpoint +
        `?q=${encodeURIComponent(searchParameters.query ?? "")}` +
        `&field=${encodeURIComponent(searchParameters.field ?? "rating")}` +
        `&ascending=${encodeURIComponent(searchParameters.ascending ?? true)}` +
        `&range=${encodeURIComponent(searchParameters.range ?? "")}` +
        `&date=${encodeURIComponent(searchParameters.date ?? "")}` +
        `&genres=${encodeURIComponent(searchParameters.genres ?? "")}`
    )
      .then((result) => result.json())
      .then((json) => {
        this.setState({
          movieIds: json.movieIds,
          total: json.total,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });

        // refresh - workaround for occassiaonally unrendered movie items
        window.scrollBy(0, 2);
        window.scrollBy(0, -2);
      });
  }

  _fetchMoviesWithParams(location) {
    if (location.pathname === this.path) {
      const params = new URLSearchParams(location.search);

      const cookies = new Cookies();
      const filterRange = cookies.get("filter-range");

      this._fetchMovies({
        query: params.get("q"),
        field: params.get("field"),
        ascending: params.get("ascending") === "false" ? "false" : "true",
        range: filterRange ?? params.get("range"),
        date: params.get("date"),
        genres: params.get("genres"),
      });
    }
  }

  _openMovieDetail = (id) => {
    this.movieDetail.current.showMovie(id);
  };

  componentDidMount() {
    this.path = window.location.pathname;

    const { history } = this.props;
    this.unlisten = history.listen((location, action) => {
      this._fetchMoviesWithParams(location);
    });

    this._fetchMoviesWithParams(window.location);
  }

  componentWillUnmount() {
    this.unlisten();
  }
}

export default withRouter(MoviesPage);
