import React from "react";
import Slider from "rc-slider";
import debounce from "lodash.debounce";

const { Range } = Slider;

class PerformanceTimeSlider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      from: props.min,
      to: props.max,
    };
  }

  _onSliderChange = (value) => {
    this.setState({ from: value[0], to: value[1] });
    this._rangeChange();
  };

  _rangeChange = debounce(() => {
    if (this.props.onRangeChange) {
      this.props.onRangeChange({ from: this.state.from, to: this.state.to });
    }
  }, 500);

  componentDidUpdate(prevProps, prevState) {
    if (this.props.range && prevProps.range !== this.props.range) {
      const range = this._parseRange(this.props.range);

      if (range.from !== this.state.from || range.to !== this.state.to) {
        this.setState({ from: range.from, to: range.to });
      }
    }
  }

  _parseRange(range) {
    const rng = range.split("-");

    if (rng.length === 2) {
      const from = parseInt(rng[0]);
      const to = parseInt(rng[1]);

      if (to >= from && from >= this.props.min && to <= this.props.max) {
        return { from: from, to: to };
      }
    }

    return { from: this.state.from, to: this.state.to };
  }

  render() {
    return (
      <div className="performance-range">
        <img src={process.env.PUBLIC_URL + "/img/clock.svg"} alt="" />
        <span>{this.state.from}:00</span>
        <Range
          allowCross={false}
          dots
          defaultValue={[this.props.min, this.props.max]}
          value={[this.state.from, this.state.to]}
          step={1}
          min={this.props.min}
          max={this.props.max}
          onChange={this._onSliderChange}
        />
        <span>{this.state.to}:00</span>
      </div>
    );
  }
}

export default PerformanceTimeSlider;
