import React from "react";
import { Link } from "react-router-dom";

function HomePage(props) {
  return (
    <div className="homepage-menu">
      <img
        className="vkine-logo"
        src={process.env.PUBLIC_URL + "/img/vkine-popcorn-main-menu.svg"}
        alt="v kine.art logo"
      />
      <div className="homepage-item">
        <button>
          <Link to="/dnes">Dávají v kinech</Link>
        </button>
        <p>
          Program kin, který je momentálně aktuální. Vyberte si od kdy do kdy.
        </p>
      </div>
      <div className="homepage-item">
        <button>
          <Link to="/novinky?field=premiere&ascending=true">Co nás čeká</Link>
        </button>
        <p>
          Filmy, které mají distributorem ohlášenou premiéru, ale ještě je
          nemáme na programu v kinech.
        </p>
      </div>
      <div className="homepage-item">
        <button>
          <Link to="/speciality">Speciality</Link>
        </button>
        <p>
          Filmy, které mají méně než 4 představení. Většinou obnovené premiéry
          nebo art.
        </p>
      </div>
      <div className="homepage-item">
        <button>
          <Link to="/vse">Všechno</Link>
        </button>
        <p>
          Všechno co máme v programech.
        </p>
      </div>
    </div>
  );
}

export default HomePage;
