import React from "react";

function FilteredCount(props) {
  const { filtered, total } = props;

  return (
    <div className="movie-filtered-count">
      <img src={process.env.PUBLIC_URL + "/img/filter.svg"} alt="" />
      <span>{`${filtered} / ${total}`}</span>
    </div>
  );
}

export default FilteredCount;
