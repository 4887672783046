import React from "react";

class Rating extends React.PureComponent {
  render() {
    let pillColor = "black";
    let textColor = "white";

    switch (this.props.rating.database) {
      case "IMDB":
        pillColor = "#ffc800";
        textColor = "black";
        break;
      case "ČSFD":
        pillColor = "rgb(186, 3, 5)";
        break;
      case "TMDB":
        pillColor = "rgb(34, 82, 138)";
        break;
      case "FFFILM":
        pillColor = "rgb(195, 190, 255)";
        textColor = "black";
        break;
      default:
        return "";
    }

    const rating = this.props.rating.value
      ? this.props.rating.value / 10.0
      : null;

    const title = rating ? "★" : this.props.rating.database;

    return (
      <>
        {this.props.rating ? (
          <a href={this.props.rating.url} target="_blank" rel="noopener noreferrer">
            <div
              title={this.props.rating.database}
              className="rating"
              style={{ backgroundColor: pillColor }}
            >
              <span className="rating-database" style={{ color: textColor }}>
                {title}
              </span>
              <span className="rating-value" style={{ color: textColor }}>
                {rating ?? ""}
              </span>
            </div>
          </a>
        ) : null}
      </>
    );
  }
}

export default Rating;
