import React from "react";

class SortSwitch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      ascending: props.ascending,
    };
  }

  handleClick(e) {
    e.stopPropagation();
    this.props.onClick(e);
  }

  setActive(isActive, ascending) {
    this.setState({ active: isActive, ascending: ascending });
  }

  getState() {
    return { active: this.state.active, ascending: this.state.ascending };
  }

  render() {
    return (
      <button
        className="sort-label"
        value={this.props.value}
        onClick={this.handleClick.bind(this)}
      >
        <img
          src={process.env.PUBLIC_URL + "/img/" + this.props.img}
          alt={this.props.value}
        />
        {this.state.active && (
          <img
            className="sort-arrow"
            src={
              process.env.PUBLIC_URL +
              (this.state.ascending
                ? "/img/arrow-up.svg"
                : "/img/arrow-down.svg")
            }
            alt={
              this.state.ascending ? "Seřazeno vzestupně" : "Seřazeno sestupně"
            }
          />
        )}
      </button>
    );
  }
}

export default SortSwitch;
