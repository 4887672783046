import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";

function YouTubeTrailers({ trailers }) {
  const opts = {
    height: "100",
    width: "160",
    playerVars: {
      autoplay: 0,
    },
  };

  const [players, setPlayers] = useState([]);

  function onReady(e) {
    setPlayers([...players, e.target]);
  }

  function onPlay(e) {
    players.map((player) => {
      if (e.target !== player) {
        player.pauseVideo();
      }

      return null;
    });
  }

  useEffect(() => {
    return () => {
      players.map((player) => {
        return player.pauseVideo();
      });
    };
  }, [players]);

  return (
    <ul className="trailer-list">
      {trailers.map((trailer) => (
        <li key={trailer.key}>
          <YouTube
            opts={opts}
            videoId={trailer.key}
            onReady={onReady}
            onPlay={onPlay}
            iframeClassName="trailer-frame"
          />
        </li>
      ))}
    </ul>
  );
}

export default YouTubeTrailers;
