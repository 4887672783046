import React from "react";
import Movie from "./movie";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import LazyLoad from "react-lazyload";

class MovieList extends React.PureComponent {
  render() {
    return (
      <>
      {this.props.movieIds.length === 0 && (
        <p className="movies-no-item">Nic tu nemáme</p>
      )}
      <ResponsiveMasonry
        columnsCountBreakPoints={{
          0: 1,
          300: 2,
          450: 3,
          600: 4,
          800: 5,
          1000: 6,
          1200: 7,
          1400: 8,
          1500: 9,
          1600: 10,
          1700: 11,
          1800: 12,
        }}
      >
        <Masonry gutter="1em" className={this.props.className}>
          {this.props.movieIds.map((id) => (
            <LazyLoad
              key={`lazy-movie-key-${id}`}
              height="20em"
              once={true}
            >
              <Movie
                id={id}
                key={`movie-key-${id}`}
                onClick={() => this._handleClick(id)}
              />
            </LazyLoad>
          ))}
        </Masonry>
      </ResponsiveMasonry>
      </>
    );
  }

  _handleClick(id) {
    if (this.props.onMovieDetailClick) {
      this.props.onMovieDetailClick(id);
    }
  }
}

export default MovieList;
