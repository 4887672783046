import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MoviesPage from "./components/movies-page";
import HomePage from "./components/home-page";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route key="page-today" path="/dnes">
              <MoviesPage
                endpoint="/s-movies"
                title="V kinech"
                showRange={true}
              />
            </Route>
            <Route key="page-premieres" path="/novinky">
              <MoviesPage
                endpoint="/s-premieres"
                title="Co se chystá"
                premiereVisible
              />
            </Route>
            <Route key="page-rares" path="/speciality">
              <MoviesPage
                endpoint="/s-rares"
                title="Obnovené premiéry a rarity"
              />
            </Route>
            <Route key="page-all" path="/vse">
              <MoviesPage endpoint="/s-all" title="Všechno co máme" />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
