import React, { useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

function PopupMenu() {
  const [isOpen, setOpen] = useState(false);

  return (
    <Popup
      trigger={
        <button className="popup-trigger">
          <img
            src={process.env.PUBLIC_URL + "/img/menu.svg"}
            title="menu"
            alt="menu"
          />
        </button>
      }
      position="left top"
      lockScroll
      closeOnDocumentClick
      closeOnEscape
      arrow={false}
      onOpen={() => setOpen(true)}
      open={isOpen}
    >
      <nav onClick={() => setOpen(false)}>{_navigation()}</nav>
    </Popup>
  );
}

const _navigation = () => {
  return (
    <ul>
      <li>
        <Link to="/">Domů</Link>
      </li>
      <li>
        <Link to="/dnes">V kinech</Link>
      </li>
      <li>
        <Link to="/novinky?field=premiere&ascending=true">Co se chystá</Link>
      </li>
      <li>
        <Link to="/speciality">Speciality</Link>
      </li>
      <li>
        <Link to="/vse">Všechno</Link>
      </li>
    </ul>
  );
};

export default PopupMenu;
