import React from "react";

class CloseButton extends React.PureComponent {
  render() {
    return (
      <button className="movie-detail-close" onClick={() => this._close()}>
        ⮐
      </button>
    );
  }

  _close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
}

export default CloseButton;
