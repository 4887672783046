import React from "react";
import RatingList from "./rating-list.js";
import Flags from "./flags.js";
import Release from "./movie-release.js";
import Premiere from "./premiere.js";
class Movie extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      movieName: null,
      originalName: null,
      posterUrl: null,
      rating: null,
      released: null,
      averageRating: null,
      countries: [],
      isDetailOpen: false,
    };
  }

  handleClick(event) {
    if (
      event.target &&
      (event.target.classList.contains("poster-img") ||
        event.target.classList.contains("poster-placeholder"))
    ) {
      event.stopPropagation();

      if (this.props.onClick) {
        this.props.onClick(event);
      }
    }
  }

  render() {
    return (
      <article id={this.props.id} className="movie-frame">
        <div className="clickable-poster" onClick={this.handleClick.bind(this)}>
          {this.state.posterUrl && (
            <div className="img-fade">
              <div className="img-wrap">
                <img
                  src={this.state.posterUrl}
                  alt={`Plakát filmu ${this.state.movieName}`}
                  className="poster-img"
                />
              </div>
            </div>
          )}
          {this.state.isLoaded && !this.state.posterUrl && (
            <div className="img-fade">
              <div className="img-wrap">
                <img
                  className="poster-placeholder"
                  src={process.env.PUBLIC_URL + "/img/poster-placeholder.svg"}
                  alt={`Plakát filmu ${this.state.movieName} není k dispozici`}
                />
              </div>
            </div>
          )}
          <RatingList rating={this.state.rating} />
        </div>
        <div className="movie-info">
          <h1>{this.state.movieName}</h1>
          {this.state.originalName !== this.state.movieName && (
            <h2>{this.state.originalName}</h2>
          )}
          <div className="movie-flags">
            <Release released={this.state.released} />
            <Flags countries={this.state.countries} maxFlags={3} />
          </div>
        </div>
        {!this.state.hasPerformance && (
          <Premiere date={this.state.latestCzechPremiere} />
        )}
      </article>
    );
  }

  componentDidMount() {
    fetch(`/s-movie/${this.props.id}`)
      .then((result) => result.json())
      .then((json) => {
        this.setState({
          isLoaded: true,
          movieName: json.czechName,
          originalName: json.originalName,
          posterUrl: json.posterUrl,
          rating: json.rating.ratings,
          homepage: json.homepage,
          countries: json.countries,
          runtime: json.runtime,
          released: json.year,
          latestCzechPremiere: json.latestCzechPremiere,
          hasPerformance: json.hasPerformance,
        });
      });
  }
}

export default Movie;
