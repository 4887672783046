import React from "react";
import { withRouter } from "react-router-dom";
import SortSwitch from "./sort-switch";
import { DebounceInput } from "react-debounce-input";
import PerformanceTimeSlider from "./performance-time-slider";
import FilteredCount from "./filtered-count";
import SortButton from "./sort-button";
import DayPicker, { DateUtils } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/cs";
import moment from "moment";
import ClickOutsideDetector from "./click-outside-detector";
import {
  cookieGetFilterRange,
  cookieSetFilterRange,
} from "../utilities/cookie-store";
import GenresSwitch from "./genres-switch";

const day = {
  1: "Pondělí",
  2: "Úterý",
  3: "Středa",
  4: "Čtvrtek",
  5: "Pátek",
  6: "Sobota",
  7: "Neděle",
};
class Sort extends React.PureComponent {
  constructor(props) {
    super(props);

    this.defaultFilterRange = cookieGetFilterRange();

    this.state = {
      searchText: null,
      calendarVisible: false,
      premiereVisible: false,
      date: this._getCurrentDateText(moment().format("YYYY-MM-DD")),
      selectedDays: { from: undefined, to: undefined },
    };

    this.rating = React.createRef();
    this.name = React.createRef();
    this.premiere = React.createRef();
  }

  _getCurrentDateText(date) {
    const dates = date.split(",");
    const dateFrom = dates[0];
    const dateTo = dates.length > 1 ? dates[1] : dateFrom;

    const mDateFrom = moment(dateFrom);
    const mDateTo = moment(dateTo);
    const today = moment().startOf("day").format("YYYY-MM-DD");
    const mYesterday = moment(dateFrom).subtract(1, "days");

    const selectedDays = moment.duration(mDateTo.diff(mDateFrom));
    const days = Math.round(selectedDays.asDays());

    let calendarText = "";

    if (today === mDateFrom.format("YYYY-MM-DD")) {
      calendarText = "dnes";
    } else if (today === mYesterday.format("YYYY-MM-DD")) {
      calendarText = "zítra";
    } else {
      calendarText = `${day[mDateFrom.isoWeekday()]} ${mDateFrom.format(
        "DD.MM."
      )}`;
    }

    if (days > 0) {
      calendarText += ` +${days}`;
    }

    return calendarText;
  }

  sortClick = (changeEvent) => {
    const searchParameters = this._getParametersFromUrl();

    if (changeEvent.target.value === searchParameters.field) {
      searchParameters.ascending =
        searchParameters.ascending === "true" ? "false" : "true";
    } else {
      searchParameters.field = changeEvent.target.value;
    }

    this._setParametersToUrl(searchParameters);
    this.setSearchState(searchParameters);
  };

  textChanged = (text) => {
    const searchParameters = this._getParametersFromUrl();

    searchParameters.query = text;

    this._setParametersToUrl(searchParameters);
  };

  setSearchState(searchParameters) {
    this.name.current.setActive(
      searchParameters.field === "name",
      searchParameters.ascending === "true"
    );

    this.rating.current.setActive(
      searchParameters.field === "rating",
      searchParameters.ascending === "true"
    );

    this.premiere.current &&
      this.premiere.current.setActive(
        searchParameters.field === "premiere",
        searchParameters.ascending === "true"
      );

    const selectedDays = this._getDateRange(searchParameters.date);

    this.setState({
      searchText: searchParameters.query,
      range: searchParameters.range,
      selectedDays: selectedDays,
      genres: searchParameters.genres,
      date: this._getCurrentDateText(
        searchParameters.date || moment().startOf("day").format("YYYY-MM-DD")
      ),
    });
  }

  _getDateRange(dateRange) {
    if (!dateRange) {
      return null;
    }

    const dates = dateRange.split(",");
    const dateFrom = dates[0];
    const dateTo = dates.length > 1 ? dates[1] : dateFrom;

    const mDateFrom = moment(dateFrom);
    const mDateTo = moment(dateTo);

    const days = {
      from: mDateFrom.toDate(),
      to: mDateTo.toDate(),
    };

    return days;
  }

  _getParametersFromUrl() {
    const today = moment().format("YYYY-MM-DD");

    const params = {
      field: "rating",
      ascending: "false",
      query: "",
      range: this.defaultFilterRange ?? "7-24",
      date: `${today},${today}`,
    };

    const { history } = this.props;
    if (history) {
      const urlParams = new URLSearchParams(window.location.search);

      params.ascending =
        urlParams.get("ascending") === "false" ? "false" : "true";

      params.field = urlParams.get("field") || "rating";
      params.query = urlParams.get("q");
      if (urlParams.get("range")) {
        params.range = urlParams.get("range");
      }
      if (urlParams.get("date")) {
        params.date = urlParams.get("date");
      }
      if (urlParams.get("genres")) {
        params.genres = urlParams.get("genres");
      }
    }

    return params;
  }

  _setParametersToUrl = (searchParameters) => {
    const { history } = this.props;

    if (history) {
      const params = new URLSearchParams(window.location.search);

      params.delete("field");
      params.delete("ascending");
      params.delete("q");
      params.delete("range");
      params.delete("date");
      params.delete("genres");

      if (searchParameters.field) {
        params.set("field", searchParameters.field);
      }

      if (searchParameters.ascending) {
        params.set("ascending", searchParameters.ascending);
      }

      if (searchParameters.query) {
        params.set("q", searchParameters.query);
      }

      if (searchParameters.range) {
        params.set("range", searchParameters.range);
      }

      if (searchParameters.date) {
        params.set("date", searchParameters.date);
      }

      if (searchParameters.genres) {
        params.set("genres", searchParameters.genres);
      }

      history.replace("?" + params.toString());
    }
  };

  componentDidMount() {
    const searchParameters = this._getParametersFromUrl();

    this.setSearchState(searchParameters);
  }

  _onRangeChanged(range) {
    const searchParameters = this._getParametersFromUrl();

    searchParameters.range = `${range.from}-${range.to}`;

    cookieSetFilterRange(searchParameters.range);

    this._setParametersToUrl(searchParameters);
  }

  _onCalendar() {
    this._showCalendar();
  }

  _showCalendar() {
    const modal = document.querySelector(".calendar-container");

    document.documentElement.classList.add("is-locked");
    modal.style.display = "block";

    modal.addEventListener("pointermove", this._preventDefault);
  }

  _hideCalendar() {
    const modal = document.querySelector(".calendar-container");

    modal.removeEventListener("pointermove", this._preventDefault);

    document.documentElement.classList.remove("is-locked");
    modal.style.display = "none";
  }

  _onCalendarSelect(range) {
    if (range && range.from && range.to) {
      const dateFrom = moment(range.from);
      const dateTo = moment(range.to ?? range.from);

      const searchParameters = this._getParametersFromUrl();
      searchParameters.date = `${dateFrom.format("YYYY-MM-DD")},${dateTo.format(
        "YYYY-MM-DD"
      )}`;

      this._setParametersToUrl(searchParameters);
      this.setSearchState(searchParameters);

      this._hideCalendar();
    }
  }

  _handleDayClick(day, modifiers = {}) {
    if (modifiers.disabled) {
      return;
    }
    const range = DateUtils.addDayToRange(day, this.state.selectedDays);
    this.setState({ selectedDays: range });

    this._onCalendarSelect(range);
  }

  _handleGenres(genres) {
    const searchParameters = this._getParametersFromUrl();

    searchParameters.genres = genres;

    this._setParametersToUrl(searchParameters);
  }

  render() {
    const { from, to } = this.state.selectedDays;
    const modifiers = { start: from, end: to };
    const disabledDays = [{ before: new Date() }];

    return (
      <>
        <SortSwitch
          value="rating"
          active={true}
          ascending={false}
          ref={this.rating}
          onClick={this.sortClick}
          img="star.svg"
        />
        <SortSwitch
          value="name"
          ref={this.name}
          onClick={this.sortClick}
          img="az.svg"
        />
        <GenresSwitch genres={this.state.genres} onFiltered={(genres) => this._handleGenres(genres)} />
        {this.props.premiereVisible && (
          <SortSwitch
            value="premiere"
            ref={this.premiere}
            onClick={this.sortClick}
            img="curtains.svg"
          />
        )}
        {this.props.showRange && (
          <>
            <SortButton
              img="calendar.svg"
              value={this.state.date}
              onClick={this._onCalendar.bind(this)}
            />
            <div className="calendar-container">
              <div className="calendar-background">
                <ClickOutsideDetector
                  listen
                  onClickOutside={() => this._hideCalendar()}
                >
                  <DayPicker
                    className="day-picker"
                    localeUtils={MomentLocaleUtils}
                    locale={"cs"}
                    selectedDays={[from, { from, to }]}
                    modifiers={modifiers}
                    disabledDays={disabledDays}
                    numberOfMonths={2}
                    onDayClick={this._handleDayClick.bind(this)}
                  />
                </ClickOutsideDetector>
              </div>
            </div>
          </>
        )}
        <div className="search-label">
          <DebounceInput
            className="search-query"
            minLength={2}
            debounceTimeout={750}
            placeholder="hledej"
            value={this.state.searchText}
            onChange={(event) => this.textChanged(event.target.value)}
          ></DebounceInput>
        </div>
        <FilteredCount
          filtered={this.props.filtered}
          total={this.props.total}
        />
        {this.props.showRange && (
          <PerformanceTimeSlider
            range={this.state.range}
            min={7}
            max={24}
            onRangeChange={this._onRangeChanged.bind(this)}
          />
        )}
      </>
    );
  }
}

export default withRouter(Sort);
