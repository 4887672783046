import React from "react";

class Overlay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  render() {
    return (
      this.state.isVisible && (
        <div className="overlay-loading">
          <p>Moment prosím...</p>
        </div>
      )
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.isDisplayed !== prevProps.isDisplayed) {
      if (this.props.isDisplayed) {
        setTimeout(() => {
          // only display overlay if the isDisplayed didn't change back to false in the meantime!
          if (this.props.isDisplayed) {
            this.setState({ isVisible: true });
          }
        }, 1000);
      } else {
        this.setState({ isVisible: false });
      }
    }
  }
}

export default Overlay;
