import React from "react";
import Rating from "./rating";

function RatingList(props) {
  return (
    <>
      {props.rating ? (
        <ul className="rating-list">
          {props.rating.map((rating) => (
            <li key={rating.database}>
              <Rating rating={rating} />
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
}

export default RatingList;
